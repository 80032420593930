.wrapper {
	max-width: none;
	@media (min-width: theme('screens.md')) {
		max-width: fit-content;
	}
}

.image {
	height: 282px;

	@media (min-width: theme('screens.md')) {
		height: 472px;
	}

	@media (min-width: theme('screens.lg')) {
		height: 582px;
	}
}
