.rich-text {
  @apply break-words;

	p {
		@apply mb-6;
	}
	ul,
	ol {
		@apply mb-6;
	}
	a {
		@apply text-brand-ochre-red underline hover:text-action-red-shade;
	}
}
